import React from "react"
import {isChrome,isFirefox,isIeEdge,getBrowser} from "@tightrope/lpscripts/browserdetect"
import Styles from "./css/chromelp.module.scss"
import Data from "./data/chromelp.json"
import Footerlinks from "@tightrope/footerlinks/footer"


class chromelp extends React.Component {
    constructor(props){
      super(props);
      this.state = {
        isChrome: isChrome(),
        isFirefox: isFirefox(),
        isIeEdge: isIeEdge(),
        display: 'none',
        pageData: props.data,
        data: Data,
        isGerman: false,
        isFrench: false
      }
    }

  showBrowsers(browsers){
    let display = false;
    if(getBrowser() === 'ie' && browsers.includes('ie')){
      display=true;
    }
    if(getBrowser() === 'firefox' && browsers.includes('firefox')){
      display=true;
    }
    if(getBrowser() === 'chrome' && browsers.includes('chrome')){
      display=true;
    }
    if(getBrowser() === 'edge' && browsers.includes('edge')){
      display=true;
    }
    if(getBrowser() === 'other' && browsers.includes('other')){
      display=true;
    }
    if(display)
      this.setState({display: 'block'});
    else
      this.setState({display: 'none'});
  }

  componentDidMount() {
    this.setState({
      isGerman: typeof window !== 'undefined' && window.location.pathname.includes('spf-de-chroma'),
      isFrench: typeof window !== 'undefined' && window.location.pathname.includes('spf-fr-chroma')
    });

    if(this.props.browsers){
      this.showBrowsers(this.props.browsers);
    }
    else{
      this.setState({display: 'block'});
    }
    if(this.props.data){
      this.props.data.then(data => {
        const instanceData = Object.assign(this.state.data, data);
        this.setState({ data: instanceData});
      this.state.isChrome = isChrome();
      this.state.isFirefox = isFirefox();
      this.state.isIeEdge = isIeEdge();

      if(isChrome()){
        const browserData = Object.assign(this.state.data, this.state.data.chrome);
        this.setState({data: browserData});
      }
      if(isFirefox()){
        const browserData = Object.assign(this.state.data, this.state.data.firefox);
        this.setState({data: browserData});
      }

      });
    }
  }
  render() {
    return (
      <>
      <div id = {Styles.chromelp}>
        <div className = {Styles.header}><img src={this.state.data.topLogo} alt="" /></div>
     <div className = {Styles.main}>
       <h1 className = "hc_param">{this.state.data.headline}</h1>

       <div className = {Styles.stepGrid}>
         <div className = {Styles.step}>1</div>
         <div className = {Styles.copy}>
           <h2>{this.state.data.subhead1}</h2>
           <p>{this.state.data.copy1}</p>
         </div>

         <div className = {Styles.step}>2</div>
         <div className = {Styles.copy}>
           <h2>{this.state.data.subhead2}</h2>
           <p>{this.state.data.copy2}</p>
         </div>
       </div>
       <div className = {Styles.comp}>{this.state.data.compliance}</div>
       <button className = {[Styles.download, Styles.cl1].join(' ')} data-cy="cta" onClick = {() => window.triggerInstall()}>{this.state.data.button}</button>

     <div className={Styles.disclosure}>
       {this.state.isGerman ? (
         <>
           Durch das Herunterladen von Carbonate stimmen Sie den{' '}
           <a href="https://carbonatebrowser.com/terms-of-service/" target="_blank">
             Servicebedingungen
           </a> und der{' '}
           <a href="https://carbonatebrowser.com/privacy-policy/" target="_blank">
             Datenschutzrichtlinie
           </a> zu.
         </>
       ) : this.state.isFrench ? (
         <>
           En téléchargeant Carbonate, vous acceptez les{' '}
           <a href="https://carbonatebrowser.com/terms-of-service/" target="_blank">
             Conditions d'utilisation
           </a> et la{' '}
           <a href="https://carbonatebrowser.com/privacy-policy/" target="_blank">
             Politique de confidentialité
           </a>.
         </>
       ) : (
         <>
           By Downloading Carbonate, you agree to the{' '}
           <a href="https://carbonatebrowser.com/terms-of-service/" target="_blank">
             Terms of Service
           </a> and{' '}
           <a href="https://carbonatebrowser.com/privacy-policy/" target="_blank">
             Privacy Policy
           </a>.
         </>
       )}
     </div>
       </div>
        </div>
      <Footerlinks></Footerlinks>
      </>
    )
  }
}
export default chromelp
